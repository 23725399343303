import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl: string = environment.baseUrl;
  isLogin: boolean;
  roleAs: string;
  showProgress$: BehaviorSubject<boolean>;
  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) {

    this.showProgress$ = new BehaviorSubject<boolean>(false);
  }

  setProgress(value: boolean) {
    this.showProgress$.next(value);
  }

  
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  generateExcel(jsonData: any[], fileName: string): void {

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }
  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }


  toastrSuccess(message: string, title: string = 'Success') {
    this.toastr.success(message, title);
  }
  toastrError(message: string, title: string = 'Error') {
    this.toastr.error(message, title);
  }
  toastrWarning(message: string, title: string = 'Warning') {
    this.toastr.warning(message, title);
  }

  toastrInfo(message: string, title: string = 'Info') {
    this.toastr.info(message, title);
  }

  Login(email, password) {
    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('password', password);

    let data = {
      email: email,
      password: password
    }

    return this.http.post(this.baseUrl + 'login', data);
  }

  forgotPassword(email: any) {
    return this.http.post(this.baseUrl + 'forgot', { email: email });
  }

  resetPassword(data: any) {
    return this.http.post(this.baseUrl + 'reset', data);
  }

  isLoggedIn() {
    const loggedIn = localStorage.getItem('STATE');
    if (loggedIn == 'true')
      this.isLogin = true;
    else
      this.isLogin = false;
    return this.isLogin;
  }

  getRole() {
    this.roleAs = localStorage.getItem('ROLE');
    return this.roleAs;
  }

  logout() {
   
    //clear all local storage
    localStorage.clear();
    this.router.navigate(['/login']);

  }


  getToken(): string | null {
    return localStorage.getItem('token');
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('refreshToken');
  }

  setRefreshToken(refreshToken: string) {
    localStorage.setItem('refreshToken', refreshToken);
  }


  refreshToken(token: string) {
    return this.http.post(this.baseUrl + 'refresh', {
      refresh: token
    });
  }


  getProfile() {
    return this.http.get(this.baseUrl + 'profile', httpOptions);

  }



  getAllQuotes() {
    return this.http.get(this.baseUrl + 'get_quotes/all', httpOptions);
  }
  getAllQuotesByID(id) {
    return this.http.get(this.baseUrl + 'get_quotes/'+id, httpOptions);
  }

  getQuotesFilter(type, value) {
    return this.http.get(this.baseUrl + 'get_quotes?filter=' + type + '&fields=' + value, httpOptions);
  }

  deleteQuote(id: any) {
    return this.http.delete(this.baseUrl + 'get_quotes/' + id, httpOptions);

  }

  updateQuote(id: any, data: any) {
    return this.http.patch(this.baseUrl + 'get_quotes/' + id, data, httpOptions);

  }
  getAllQuotesTrasaction() {
    return this.http.get(this.baseUrl + 'quote_transactions/all', httpOptions);
  }

  getQuotesFilterTransaction(type, value) {
    return this.http.get(this.baseUrl + 'quote_transactions?filter=' + type + '&fields=' + value, httpOptions);
  }

  deleteQuoteTransaction(id: any) {
    return this.http.delete(this.baseUrl + 'quote_transactions/' + id, httpOptions);

  }

  updateQuoteTransaction(id: any, data: any) {
    return this.http.patch(this.baseUrl + 'quote_transactions/' + id, data, httpOptions);

  }


  getServicesDetails() {
    return this.http.get(this.baseUrl + 'services', httpOptions);
  }
  getServices() {
    return this.http.get(this.baseUrl + 'services/all', httpOptions);
  }

  getServicesPublic() {
    return this.http.get(this.baseUrl + 'services/public');
  }

  getAServices(id: any) {
    return this.http.get(this.baseUrl + 'services/' + id, httpOptions);
  }
  createService(data: any) {
    return this.http.post(this.baseUrl + 'services', data, httpOptions);
  }

  getServicesFilter(type, value) {
    return this.http.get(this.baseUrl + '/services?filter=' + type + '&fields=' + value, httpOptions);
  }

  deleteService(id: any) {
    return this.http.delete(this.baseUrl + 'services/' + id, httpOptions);

  }

  updateService(id: any, data: any) {
    return this.http.patch(this.baseUrl + 'services/' + id, data, httpOptions);

  }


  fileupload(file: any, type: any, name?: any, folder?: any, subfolder?: any) {
    const formdata = new FormData();


    formdata.append("name", name ? name : 'unnamed');
    formdata.append("folder", folder ? folder : 'other');
    formdata.append("subfolder", subfolder ? subfolder : 'admin');
    formdata.append("file", file);
    formdata.append("content_type", type);
    console.log(formdata);
    return this.http.post(this.baseUrl + 'files/admin/upload', formdata);
  }

  createTestimonial(data: any) {
    return this.http.post(this.baseUrl + 'testimonial', data, httpOptions);
  }

  getAllTestimonial() {
    return this.http.get(this.baseUrl + 'testimonial/all', httpOptions);
  }
  deleteTestimonial(id: any) {
    return this.http.delete(this.baseUrl + 'testimonial/' + id, httpOptions);

  }

  createCaQuestion(data: any) {
    return this.http.post(this.baseUrl + 'askaca', data, httpOptions);
  }

  getAllCaQuestion() {
    return this.http.get(this.baseUrl + 'askaca/all', httpOptions);
  }
  deleteCaQuestion(id: any) {
    return this.http.delete(this.baseUrl + 'askaca/' + id, httpOptions);

  }
  
  updateCaStatus(id: any, data: any) {
    return this.http.patch(this.baseUrl + 'askaca/' + id, data, httpOptions);

  }


  getUsers() {
    return this.http.get(this.baseUrl + 'users?limit=' + 1000, httpOptions);
  }
  getUsersb2b() {
    return this.http.get(this.baseUrl + 'users?filter=b2b&fields=role&limit=' + 1000, httpOptions);
  }
  getUsersaffiliate() {
    return this.http.get(this.baseUrl + 'users?filter=affiliate&fields=role&limit=' + 1000, httpOptions);
  }

  createUser(data: any) {
    return this.http.post(this.baseUrl + 'users', data, httpOptions);
  }

  updateUser(id: any, data: any) {
    return this.http.patch(this.baseUrl + 'users/' + id, data, httpOptions);

  }

  createTemplate(data: any) {
    return this.http.post(this.baseUrl + 'templates', data, httpOptions);
  }

  updateTemplate(id: any, data: any) {
    return this.http.patch(this.baseUrl + 'templates/' + id, data, httpOptions);
  }

  getTemplateById(id: any) {
    return this.http.get(this.baseUrl + 'templates/' + id, httpOptions);
  }

  getAlltemplate() {
    return this.http.get(this.baseUrl + 'templates/all', httpOptions);
  }

  getAlltemplateFilter(type, value) {
    return this.http.get(this.baseUrl + 'templates?filter=' + type + '&fields=' + value, httpOptions);
  }

  deleteTemplate(id: any) {
    return this.http.delete(this.baseUrl + 'templates/' + id, httpOptions);

  }


  createClient(data: any) {
    return this.http.post(this.baseUrl + 'clients', data, httpOptions);
  }

  updateClient(id: any, data: any) {
    return this.http.patch(this.baseUrl + 'clients/' + id, data, httpOptions);
  }

  getClientById(id: any) {
    return this.http.get(this.baseUrl + 'clients/' + id, httpOptions);
  }

  getAllClient() {
    return this.http.get(this.baseUrl + 'clients/all', httpOptions);
  }

  getAllClientFilter(type, value) {
    return this.http.get(this.baseUrl + 'clients?filter=' + type + '&fields=' + value, httpOptions);
  }

  deleteClient(id: any) {
    return this.http.delete(this.baseUrl + 'clients/' + id, httpOptions);
  }

  getPartnersClients(id) {
    return this.http.get(this.baseUrl + 'clients/partner/' + id, httpOptions);
  }

  createClientOrder(data: any) {
    return this.http.post(this.baseUrl + 'client_orders', data, httpOptions);
  }
  updateClientOrder(id: any, data: any) {
    return this.http.patch(this.baseUrl + 'client_orders/' + id, data, httpOptions);
  }
  getClientorderByPartner(id: any) {
    return this.http.get(this.baseUrl + 'client_orders/partner/' + id, httpOptions);
  }

  getAClientOrder(id: any) {
    return this.http.get(this.baseUrl + 'client_orders/client/' + id, httpOptions);
  }

  verifyTransaction(data: any) {
    return this.http.post(this.baseUrl + 'transactions/verify', data, httpOptions);
  }

  getAllOrders() {
    return this.http.get(this.baseUrl + 'client_orders/all', httpOptions);
  }
  getOrderDetails(id: any) {
    return this.http.get(this.baseUrl + 'client_orders/' + id, httpOptions);
  }

  getAllTransactions() {
    return this.http.get(this.baseUrl + 'transactions/all', httpOptions);
  }

  createQuote(data: any) {
    return this.http.post(this.baseUrl + 'get_quotes', data);

  }

  getQuoteTransactions(id: any) {
    return this.http.get(this.baseUrl + 'quote_transactions/all/' + id, httpOptions);

  }

  quoteCashPayment(data: any) {
    return this.http.post(this.baseUrl + 'quote_transactions/pay/admin', data, httpOptions);
  }



  getGrantById(id: any) {
    return this.http.get(this.baseUrl + 'clients/' + id, httpOptions);
  }

  getAllGrants() {
    return this.http.get(this.baseUrl + 'grants/all', httpOptions);
  }

  updateGrant(id: any, data: any) {
    return this.http.patch(this.baseUrl + 'grants/' + id, data, httpOptions);
  }

  createGrant(data: any) {
    return this.http.post(this.baseUrl + 'grants', data, httpOptions);
  }

  getAllGrantsFilter(type, value) {
    return this.http.get(this.baseUrl + 'grants?filter=' + type + '&fields=' + value, httpOptions);
  }

  deleteGrant(id: any) {
    return this.http.delete(this.baseUrl + 'grants/' + id, httpOptions);
  }

  getAllPartners(){
    return this.http.get(this.baseUrl + 'partners/all', httpOptions);
  }

  updatePartner(id: any, data: any) {
    return this.http.patch(this.baseUrl + 'partners/' + id, data, httpOptions);
  }

  getPartnerInfo(id: any) {
    return this.http.get(this.baseUrl + 'partners/byuser/' + id, httpOptions);
  }


  getPartnerCommissionsByPartner(id: any) {
    return this.http.get(this.baseUrl + 'partnercommissions/' + id, httpOptions);
  }
  getPartnerCommissionsAll() {
    return this.http.get(this.baseUrl + 'partnercommissions/all', httpOptions);
  }

  updatePartnerCommission(id:any,data:any){
    return this.http.patch(this.baseUrl + 'partnercommissions/' + id,data, httpOptions);
  
  }

  getAllTransactionsByPartner(id: any) {
    return this.http.get(this.baseUrl + 'transactions/partner/'+ id, httpOptions);
  }


  getCommissionRecordsByPartner(id: any) {
    return this.http.get(this.baseUrl + 'commissionrecords/partner/'+ id, httpOptions);
  }

  getCommissionRecordsAll() {
    return this.http.get(this.baseUrl + 'commissionrecords/all', httpOptions);
  }

  getPartnerSettings(id:any){
    return this.http.get(this.baseUrl + 'partnersettings/'+ id, httpOptions);
  
  }
  updatePartnerSettings(id:any,data:any){
    return this.http.patch(this.baseUrl + 'partnersettings/'+ id,data, httpOptions);
  }

  getPartnerSettlements(id:any){
    return this.http.get(this.baseUrl + 'partnersettlements/partner/'+ id, httpOptions);
  }
  createPartnerSettlement(data:any){
    return this.http.post(this.baseUrl + 'partnersettlements',data, httpOptions);
  }
  getAllPartnerSettlements(){
    return this.http.get(this.baseUrl + 'partnersettlements/all', httpOptions);
  }



// analytics apis

getOverallAnalytics(){ 
  return this.http.get(this.baseUrl + 'analytics/overall', httpOptions);
}

getOverallAnalyticsByDate(data:any){
  return this.http.post(this.baseUrl + 'analytics/overall', data, httpOptions);
}

getPartnerEarnings(id:any){
  return this.http.get(this.baseUrl + 'analytics/earnings/partner/'+ id, httpOptions);
}


generateServicePrivetKey(id:any){
  return this.http.post(this.baseUrl + 'services/publiclink/'+id, httpOptions);
}

getPartnerReferral(id:any){
  return this.http.get(this.baseUrl + 'get_quotes/referral/'+id, httpOptions);
}

 createOrderTransaction(data:any,id:any){
  return this.http.post(this.baseUrl + 'transactions/order/'+id, data, httpOptions);
}

  getOrderTransaction(id:any){
    return this.http.get(this.baseUrl + 'transactions/order/'+id, httpOptions);
  }



  readyTosettle(id:any){
    return this.http.get(this.baseUrl + 'commissionrecords/partner/'+id+'/unsetteled', httpOptions);
  }

 

  addTrasactionAttachment(id:any,data:any){
    return this.http.patch(this.baseUrl + 'transactions/upload/'+id, data, httpOptions);
  }

  updateTranscationStatus(id:any,data:any){
    return this.http.patch(this.baseUrl + 'transactions/'+id, data, httpOptions);
  }

  trademarkQuotes(){
    return this.http.get(this.baseUrl + 'trademark_quotes', httpOptions);
  }
  trademarkQuotesUpdate(id:any,data:any){
    return this.http.patch(this.baseUrl + 'trademark_quotes/'+id,data, httpOptions);
  }

  getSeos(){
    return this.http.get(this.baseUrl + 'seos', httpOptions);
  }

  updateSeo(id:any,data:any){
    return this.http.patch(this.baseUrl + 'seos/'+id,data, httpOptions);
  }

  createSeo(data:any){
    return this.http.post(this.baseUrl + 'seos',data, httpOptions);
  }


}
