
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../service/api.service';
ApiService
@Injectable({
  providedIn: 'root'
})
export class LogincheckGuard implements CanActivate {
  constructor(private authService: ApiService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(route, url);
  }



  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.authService.isLoggedIn()) {
      const userRole = this.authService.getRole();
      if (userRole == 'b2b') {
        this.router.navigate(['/admin']);
      }
      else if (userRole == 'affiliate') {
        this.router.navigate(['/affiliate']);
      }
      else if (userRole == 'admin') {
        this.router.navigate(['/superadmin']);
      }
      return false;
    }

    // this.router.navigate(['/login']);
    return true;
  }
}

